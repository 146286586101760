import React, { Fragment } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Spinner,
  Modal,
} from "react-bootstrap";

/// imge

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

import Swal from "sweetalert2";
import { deleteFormJoinApi } from "../../../store/DeleteFromJointSlicer";
import { getFormJoinApi } from "../../../store/GetFormJoinSlicer";

const JoinUsTable = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.formJoin);
  const [formData, setFormData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [showMedule, setShowMedule] = useState(false);
  const [messageData, setMessageData] = useState({
    name: "",
    email: "",
    phone: "",
    education: "",
    description: "",
  });

  const setModuleData = (data) => {
    setMessageData({
      name: data.name,
      email: data.email,
      phone: data.phone,
      education: data.education,
      description: data.description,
    });
    setShowMedule(true);
  };

  const CloseModuleData = () => {
    setMessageData({
      name: "",
      email: "",
      phone: "",
      education: "",
      description: "",
    });
    setShowMedule(false);
  };

  const handleClickDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are going to delete this Message",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFormJoinApi(id)).then((resulet) => {
          Swal.fire("Deleted!", "User has been deleted", "success");
          getAllData();
        });
      }
    });

    console.log(id);
  };

  const getAllData = () => {
    dispatch(getFormJoinApi()).then((resulet) => {
      if (resulet.payload.message === "Data sent successfully") {
        setFormData(resulet.payload.data);
        setBackupData(resulet.payload.data);
      } else {
        setFormData([]);
      }
    });
  };

  const formsFilters = (e) => {
    if (e.target.value === "") {
      setFormData(backupData);
      return;
    }

    const filterArray = backupData.filter((item) => {
      console.log(item);
      return (
        item.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.phone.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    console.log(filterArray);
    setFormData(filterArray);
  };

  useEffect(() => {
    getAllData();
  }, []);
  // const handleShowUserCV = (e) => {
  //   const file = new createUrl
  // }
  return (
    <Fragment>
      <PageTitle activeMenu="Join Us" motherMenu="Forms" />
      <div className="row">
        <div className="col-xl-12">
          <div className="card students-list">
            <div className="card-header border-0 flex-wrap ">
              <h4>All Messages ({backupData.length})</h4>
              <div className="input-group search-area w-auto">
                <span className="input-group-text">
                  <Link to={"#"}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                        fill="var(--primary)"
                      ></path>
                    </svg>
                  </Link>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  onChange={formsFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="warning" />
          </div>
        </>
      ) : (
        <>
          {formData.length == 0 ? (
            <>
              <div className="row d-flex text-center gap-2">
                <h3>There Is No Messages</h3>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col lg={12}>
                  <Card>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              <strong>Action</strong>
                            </th>
                            {/* <th>
                              <strong>Date</strong>
                            </th> */}

                            <th>
                              <strong>NAME</strong>
                            </th>
                            <th>
                              <strong>EMAIL</strong>
                            </th>
                            <th>
                              <strong>PHONE</strong>
                            </th>
                            <th>
                              <strong>RESUME</strong>
                            </th>
                            {/*
                            <th>
                              <strong>DESCRIPTION</strong>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {formData.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>
                                    <div>
                                      <button
                                        onClick={() =>
                                          handleClickDelete(item._id)
                                        }
                                        className="btn btn-danger shadow btn-xs sharp m-1"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      <button
                                        onClick={() => setModuleData(item)}
                                        className="btn btn-primary shadow btn-xs sharp m-1"
                                      >
                                        <i className="fa-solid fa-envelope-open" />
                                      </button>
                                    </div>
                                  </td>
                                  {/* <td>{item.createdAt}</td> */}
                                  <td> {item.name}</td>
                                  <td> {item.email} </td>
                                  <td> {item.phone}</td>
                                  <td>
                                  <i className="fa-solid fa-file"
                                  style={{cursor: "pointer" , fontSize: "20px"}}
                                  onClick={() => {
                                    item.file
                                    &&
                                    window.open(item.file , "_blank");
                                  }}
                                  ></i>
                                  </td>
                                  {/* <td> {item.education}</td>
                                  <td> {item.description}</td> */}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Modal
                className="modal fade"
                show={showMedule}
                onHide={CloseModuleData}
                size="lg"
              >
                <div className="" role="document">
                  <div className="">
                    <div className="modal-header">
                      <h4 className="modal-title fs-20">Read Messages</h4>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={CloseModuleData}
                        data-dismiss="modal"
                      >
                        <span></span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="add-contact-box">
                        <div className="add-contact-content">
                          <div className="row">
                            <div className="col-lg-12 col-sm-12 form-group mb-3">
                              <label className="text-black font-w500">
                                Name
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={messageData.name}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 form-group mb-3">
                              <label className="text-black font-w500">
                                email
                              </label>
                              <div className="contact-name">
                                <input
                                  type="email"
                                  className="form-control"
                                  value={messageData.email}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 col-sm-12 form-group mb-3">
                              <label className="text-black font-w500">
                                Phone
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={messageData.phone}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 form-group mb-3">
                              <label className="text-black font-w500">
                                Education
                              </label>
                              <div className="contact-name">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={messageData.education}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <label className="text-black font-w500">
                              Message
                            </label>
                            <div className="contact-name">
                              <textarea
                                disabled
                                className="form-control"
                                value={messageData.description}
                                rows={8}
                                style={{
                                  resize: "none",
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </>
      )}
    </Fragment>
  );
};

export default JoinUsTable;
