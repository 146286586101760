
import {  Button, Modal } from "react-bootstrap";

export default function MessageContent({show , hide , message , userImage}) {
    return (
        <>
            <Modal
                show={show}
                onHide={hide}
                backdrop="static"
                keyboard={false}
                size="lg"
                >
                <Modal.Header>
                    <h4>Message Content</h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="position-relative">
                        <div className="image d-flex justify-content-center align-items-center" style={{marginBlock : "10px"}}>
                            <img  src={`${process.env.REACT_APP_VERSEL_API}/${userImage}`} alt="user img" style={{maxWidth: "100px" , borderRadius: "50%" , marginBlock : "10px"}} />
                        </div>
                        <p style={{textAlign: "center"}}>{message}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={hide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
