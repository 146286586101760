import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import { LogoutFunction } from "../../../services/LogoutFunction";
import axios from "axios";

export const updateCurrencyFunc = createAsyncThunk(
  "update currency func",
  async ({data , id}) => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    // const request = await fetch(
    //   `${process.env.REACT_APP_VERSEL_API}/api/user/currencies/${id}/update`,
    //   {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${tokenData}`,
    //       // "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   }
    // )
    //   .then((respons) => respons.json())
    //   .then((resulet) => {
    //     console.log(resulet);
    //     return resulet;
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     return e;
    //   });
    const config  = {
        headers: {
          Authorization: `Bearer ${tokenData}`,
          // "Content-Type": "application/json",
        },
    }
    const request =await axios.post(`${process.env.REACT_APP_VERSEL_API}/api/user/currencies/${id}/update` , data , config);
    return request?.data;
  }
);
function formatError(errorResponse) {
  swal("Oops", `${errorResponse}`, "error");
}

const updateCurrencyApiSlice = createSlice({
  name: "currency/update",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrencyFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateCurrencyFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        formatError(state.error);
        LogoutFunction();
      }
      if (action.payload.message === "Updated Successfully") {
        state.data = action.payload;
        state.error = null;
      } else {
        state.data = null;
        state.error = action.payload.message;
      }
    });

    builder.addCase(updateCurrencyFunc.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload.message;
    });
  },
});

export default updateCurrencyApiSlice.reducer;
