import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as Yup from "yup";
import { updateJobFunc } from "../../../store/Slices/jobs/UpdateJobSlicer";
import { getJobsFunc } from "../../../store/Slices/jobs/GetJobsSlicer";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { updateCurrencyFunc } from "../../../store/Slices/currency/UpdateCurrencyApiSlice";
const updateSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter name"),
  
    code: Yup.string()
    //   .min(6, "Job postion must consist of at least 3 characters ")
    //   .max(300, "Job postion must consist of at most 300 characters ")
      .required("Please enter currency code"),
    symbol: Yup.string()
    //   .min(6, "Job postion must consist of at least 3 characters ")
    //   .max(300, "Job postion must consist of at most 300 characters ")
      .required("Please enter currency symbol"),
  
    exchange_rate: Yup.string()
    //   .min(6, "Job location must consist of at least 3 characters ")
    //   .max(300, "Job location must consist of at most 300 characters ")
      .required("Please enter currency exchange rate"),
    // arlocation: Yup.string()
    //   .min(6, "Job postion must consist of at least 3 characters ")
    //   .max(300, "Job postion must consist of at most 300 characters ")
    //   .required("Please enter a Job arabic loction"),
    // enCompanyName: Yup.string()
      // .min(6, "Job postion must consist of at least 3 characters ")
      // .max(300, "Job postion must consist of at most 300 characters ")
    //   .required("Please enter English Company Name"),
    // arCompanyName: Yup.string()
      // .min(6, "Job postion must consist of at least 3 characters ")
      // .max(300, "Job postion must consist of at most 300 characters ")
    //   .required("Please enter Arabic Company Name"),
  });

export default function UpdateCurrencyForm({show , handleCloseUpdateForm , oldData , refetchData}) {
    const {loading: updateLoading} = useSelector(
        state => state.updateCurrencyApiSlice
    )
    const dispatch = useDispatch();
    const [status , setStatus] = useState(false);
    const [jobFormIntilization, setJobFormIntilization] = useState({});
    const [id , setId] = useState();
    useEffect(() => {
        if(oldData) {
            console.log(oldData)
            setStatus(oldData.status)
            // setJobFormIntilization({
            //     name: oldData.name,
            //     symbol: oldData.symbol,
            //     code: oldData.code,
            //     exchange_rate: oldData.exchange_rate,
            // })
            setJobFormIntilization(oldData)
            setId(oldData._id);
        }
    },[oldData])
      const handleSubmitData = (value, { resetForm }) => {
        const formData = new FormData();
        formData.append("name" , value.name);
        formData.append("symbol" , value.symbol);
        formData.append("exchange_rate" , value.exchange_rate);
        formData.append("code" , value.code);
        formData.append("status" , status);
        const sendDataFunc = () => {
          dispatch(updateCurrencyFunc({data : {...value , status} , id})).then((resulte) => {
            if (resulte.payload.message === "Updated Successfully") {
                resetForm();
                setJobFormIntilization({})
                // setStatus(false);
                handleCloseUpdateForm();
                refetchData();
            } else {
              swal("error", resulte.payload.message, "warning");
            }
          });
        }
        sendDataFunc();
      };
    return (
        <>
            <Modal
            show={show}
            onHide={handleCloseUpdateForm}
            backdrop="static"
            keyboard={false}
            size="lg"
            >
            <Modal.Body>
                {
                    oldData && Object.keys(jobFormIntilization).length !== 0
                    &&
                    <Formik
                        initialValues={{
                            name: jobFormIntilization.name,
                            symbol: jobFormIntilization.symbol,
                            code: jobFormIntilization.code,
                            exchange_rate: jobFormIntilization.exchange_rate,
                        }}
                        validationSchema={updateSchema}
                        onSubmit={handleSubmitData}
                    >
                        {
                        (
                            {
                        values,
                        errors,
                        setValues,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        }) => (
                        <form className="row" onSubmit={handleSubmit}>
                            {console.log(values)}
                            <div>
                            <h1>Currency Infromation</h1>
                            <section>
                                <div className="row">
                                <div
                                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.name
                                        ? errors.name
                                        ? "is-invalid"
                                        : "is-valid"
                                        : ""
                                    }`}
                                >
                                    <label className="text-label">
                                    Enter Name
                                    </label>
                                    <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Name.."
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    <div
                                        id="val-name-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.name && errors.name}
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                <div
                                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.code
                                        ? errors.code
                                        ? "is-invalid"
                                        : "is-valid"
                                        : ""
                                    }`}
                                >
                                    <label className="text-label">
                                    Enter Code
                                    </label>
                                    <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Currency Code.."
                                        name="code"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.code}
                                    />
                                    <div
                                        id="val-name-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.code && errors.code}
                                    </div>
                                    </div>
                                </div>
                                <div
                                    className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                    values.symbol
                                        ? errors.symbol
                                        ? "is-invalid"
                                        : "is-valid"
                                        : ""
                                    }`}
                                >
                                    <label className="text-label">
                                    Enter Symbol
                                    </label>
                                    <div className="input-group">
                                    <span className="input-group-text">
                                        <i className="fa-solid fa-file-signature"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Symbol.."
                                        name="symbol"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.symbol}
                                    />
                                    <div
                                        id="val-name-error"
                                        className="invalid-feedback animated fadeInUp"
                                        style={{ display: "block" }}
                                    >
                                        {errors.symbol && errors.symbol}
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                        values.exchange_rate
                                            ? errors.exchange_rate
                                            ? "is-invalid"
                                            : "is-valid"
                                            : ""
                                        }`}
                                    >
                                        <label className="text-label">
                                        Enter Exchange Rate
                                        </label>
                                        <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Exchange Rate.."
                                            name="exchange_rate"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.exchange_rate}
                                        />
                                        <div
                                            id="val-name-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.exchange_rate && errors.exchange_rate}
                                        </div>
                                        </div>
                                    </div>
                                    {/* <div
                                        className={` col-lg-12 col-sm-12 form-group mb-3 ${
                                        values.arlocation
                                            ? errors.arlocation
                                            ? "is-invalid"
                                            : "is-valid"
                                            : ""
                                        }`}
                                    >
                                        <label className="text-label">
                                        Enter Arabic Job Location
                                        </label>
                                        <div className="input-group">
                                        <span className="input-group-text">
                                            <i className="fa-solid fa-file-signature"></i>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter a arabic location.."
                                            name="arlocation"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.arlocation}
                                        />
                                        <div
                                            id="val-name-error"
                                            className="invalid-feedback animated fadeInUp"
                                            style={{ display: "block" }}
                                        >
                                            {errors.arlocation && errors.arlocation}
                                        </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="visiblaty-div row mb-2">
                                    <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                                    <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                        onChange={(e) => {
                                        setStatus(!status);
                                        }}
                                        checked = {status ? true : false}
                                    />
                                    <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Currency Status</label>
                                    </div>
                                </div>
                                </div>
                            </section>
                            </div>

                            <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleCloseUpdateForm}
                            >
                                Close
                            </Button>
                            {updateLoading ? (
                                <>
                                <Button variant="primary" disabled>
                                    Updateting ...
                                </Button>
                                </>
                            ) : (
                                <>
                                <Button variant="primary" type="submit">
                                    Update Currency
                                </Button>
                                </>
                            )}
                            </Modal.Footer>
                        </form>
                        )
                        }
                    </Formik>
                }
            </Modal.Body>
            </Modal>
        
        </>
    )
}
