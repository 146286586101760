import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

//
import loginbg from "../../images/bg-1.jpg";
import logo from "../../images/log.png";
// import logofull from "../../images/logos.png";
import logofull from "../../images/new-logo.webp";
import { login } from "../../store/AuthSlicer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LogedIn } from "../../store/isLogedIn";

function Login(props) {
  const { loading } = useSelector((state) => state.newAuth);
  const { isLogedInState } = useSelector((state) => state.isLoged);

  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const history = useHistory();
  // const [email, setEmail] = useState('demo@example.com');
  // const [password, setPassword] = useState('123456');

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(login({ email, password })).then((resulet) => {
      console.log(resulet.payload);
      if (resulet.payload.message === "loggedIn") {
        dispatch(LogedIn());
        window.location.href = "/";
      }
    });
    // dispatch(loadingToggleAction(true));
    // dispatch(loginAction(email, password, props.history));
  }
  //   useEffect(() => {
  // 	console.log('from use effict login');
  //     if (isLogedInState) {
  // 		console.log('from use effict in if condition',isLogedInState);
  //       history.push("/dashboard");
  //     }
  //   }, [isLogedInState]);

  return (
    <div
      className="login-main-page"
      style={{
        backgroundImage: "url(" + loginbg + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="login-wrapper">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-contain-center">
            <div className="col-xl-12 mt-3">
              <div className="card">
                <div className="card-body p-0">
                  <div className="row m-0">
                    <div className="col-xl-6 col-md-6 sign text-center">
                      <div>
                        <div className="text-center my-5">
                          <Link to="/login">
                            <img width="100" src={logofull} alt="" />
                          </Link>
                        </div>
                        <img src={logo} className="education-img"></img>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                      <div className="sign-in-your">
                        <h4 className="fs-20 font-w800 text-black">
                          Sign in your account
                        </h4>
                        <span>
                          Welcome back! Login with your data that you entered
                          <br /> during registration
                        </span>
                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                        <form onSubmit={onLogin}>
                          <div className="mb-3">
                            <label className="mb-1">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="mb-3">
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          {/* <div className="row d-flex justify-content-between mt-4 mb-2">
                            <div className="mb-3">
                              <div className="form-check custom-checkbox ms-1">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="basic_checkbox_1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="basic_checkbox_1"
                                >
                                  Remember my preference
                                </label>
                              </div>
                            </div>
                            <div className="mb-3">
                              <Link to="./page-register">Sign up</Link>
                            </div>
                          </div> */}
                          <div className="text-center">
                            {loading ? (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                  disabled
                                >
                                  Loading....
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Sign Me In
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);

/**
 * <span>Welcome back! Login with your data that you entered<br /> during registration</span>
												 <div className="login-social">
													<Link to={"#"} className="btn font-w800 d-block my-4"><i className="fab fa-google me-2 text-primary"></i>Login with Google</Link>
													<Link to={"#"} className="btn font-w800 d-block my-4"><i className="fab fa-facebook-f me-2 facebook-log"></i>Login with Facebook</Link>
												</div> 
 */
