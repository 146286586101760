import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import { addJobFunc } from "../../../store/Slices/jobs/AddJobSlicer";
import { newFeedbackFunc } from "../../../store/Slices/feedbacks/NewFeedbackSlicer";
const createFeedbackSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter Email"),
  name: Yup.string()
    .required("Please enter Name"),
  message: Yup.string()
    .required("Please enter Feedback Message"),
});

const NewFeedbackForm = () => {

  const { loading, error } = useSelector((state) => state.newFeedbackSlicer);

  const dispatch = useDispatch();
  const [userdata, setUserData] = useState();
  const [visipilatyStatus , setvisipilatyStatus] = useState(false);
  const [rating , setRating] = useState(1);
  const [image , setImage] = useState(null);
  const [imageViewr , setImageViewr] = useState(null);
  const history = useHistory();
  const [inputKey, setInputKey] = useState(Date.now());
  // File Refrances Basic Images
  const [courseFormIntilization, setCourseFormInitialization] = useState({
    email : "",
    name: "",
    message: "",
  });

  // Get All Data Function
  const handleSubmitData = (value, { resetForm }) => {
    console.log(value)
    const feedbackForm = new FormData();
    feedbackForm.append("name" , value.name)
    feedbackForm.append("email" , value.email)
    feedbackForm.append("message" , value.message)
    // feedbackForm.append("rate" , rating)
    image && feedbackForm.append("image" , image)
    // feedbackForm.append("publish" , visipilatyStatus)
    
    const sendDataFunc = () => {
      dispatch(newFeedbackFunc(feedbackForm)).then((resulte) => {
        console.log(resulte)
        if (resulte.payload.message === " Feedback created Successfully") {
          Swal.fire({
            title: resulte.payload.message,
            text: "Go to Feedbacks Page !",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, stay and add new Feedback",
            confirmButtonText: "Yes, Go to Feedbacks Page",
          }).then((result) => {
            if (result.isConfirmed) {
                history.push(`/reviews`);
            } else {
              resetForm();
              setvisipilatyStatus(false)
            }
          });
          setvisipilatyStatus(false);
          setRating(null);
          setImage(null);
          setInputKey(Date.now());
          setImageViewr(null)
        } else {
          swal("error", resulte.payload.message, "warning");
        }
      });
    }
    sendDataFunc();
  };

  useEffect(() => {
    window.scroll(0, 0);
    const data = JSON.parse(localStorage.getItem("userData"));
    if (data) {
      setUserData(data);
    } else {
      history.push("/login");
    }
  }, []);

  return (
    <Fragment>
      <PageTitle activeMenu="New Support" motherMenu="Support" />
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={{
                  email: courseFormIntilization.email,
                  name: courseFormIntilization.name,
                  message: courseFormIntilization.message,
                
                }}
                validationSchema={createFeedbackSchema}
                onSubmit={handleSubmitData}
              >
                {({
                  values,
                  errors,
                  setValues,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                }) => (
                  <form className="row" onSubmit={handleSubmit}>
                    <div>
                      <h1>Review Infromation</h1>
                      <section>

                        {/*email*/}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.email
                                ? errors.email
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Email
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email.."
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*name*/}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.name
                                ? errors.name
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Name
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Name.."
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* image */}
                        {
                            imageViewr
                            &&
                            <div className="my-2 d-flex justify-content-center">
                                <img src={imageViewr} alt="img" style={{width : "100px"}} />
                            </div>
                        }
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3`}
                          >
                            <label className="text-label">
                              Enter Image (optional)
                            </label>
                            <div className="input-group">
                              {/* <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span> */}
                              <input
                                key={inputKey}
                                type="file"
                                className="form-control"
                                name="image"
                                onChange={(e) => {
                                    const file = e.target.files[0]
                                    if(file) {
                                        setImage(file);
                                        setImageViewr(URL.createObjectURL(file));
                                    }
                                }}
                                onBlur={handleBlur}
                             
                              />
                            </div>
                          </div>
                        </div>
                        {/* message */}
                        <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.message
                                ? errors.message
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Enter Support Message
                            </label>
                            <div className="input-group">
                              {/* <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span> */}
                              <textarea 
                              rows={5}
                              className="form-control"
                              placeholder="Enter Feedback Message.."
                              name="message"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                              >
                              </textarea>
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.message && errors.message}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* publish */}
                        {/* <div className="visiblaty-div row mb-2">
                            <div className="checkbox-div my-2 d-flex align-items-center bg-amber-300">
                              <input id="visiblaty"  type="checkbox" className="mr-1 cursor-pointer scale2"
                                onChange={(e) => {
                                  setvisipilatyStatus(!visipilatyStatus);
                                }}
                                checked = {visipilatyStatus}
                              />
                              <label htmlFor="visiblaty" className="is-free-label cursor-pointer pl-2 mb-0">Visability</label>
                            </div>
                        </div> */}
                        {/* rating */}
                        {/* <div className="row">
                          <div
                            className={` col-lg-12 col-sm-12 form-group mb-3 ${
                              values.message
                                ? errors.message
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">
                              Select Review Rating
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                <i className="fa-solid fa-file-signature"></i>
                              </span>
                                <select
                                className="form-control"
                                name="message"
                                onChange={(e) => {
                                    setRating(e.target.value)
                                }}
                                >
                                    {[...Array(5)].map((item , index) => (
                                        <option value={index+1}>
                                            {
                                                index+1 > 1
                                                ?
                                                `${index + 1} Stars`
                                                :
                                                `${index + 1} Star`
                                            }
                                        </option>
                                    ))}
                                </select>
                              <div
                                id="val-name-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.rate && errors.rate}
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </section>
                    </div>
                    <div className="row">
                      <div className="col">
                        {loading ? (
                          <>
                            <Button variant="primary" disabled>
                              <Spinner animation="grow" variant="info" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button variant="primary" type="submit">
                              Create
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewFeedbackForm;
