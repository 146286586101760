import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import userLoginSlicer from "./AuthSlicer";
import isLogedIn from "./isLogedIn";
import AddUserSlicer from "./AddUserSlicer";
import getAllCourses from "./GetAllCoursesSlicer";
import SingleCourseSlicer from "./SingleCourseSlicer";
import DeleteCourseSlicer from "./DeleteCourseSlicer";
import AddCourseSlicer from "./AddCourseSlicer";
import UploadDemoVideoSlicer from "./Slices/courses/UploadDemoVideoSlicer";
import UpdateImageCourseSlicer from "./Slices/courses/UpdateImageCourseSlicer";

import addessonSlicer from "./AddessonSlicer";
import getAllLessonsSlicer from "./GetAllLessonSlicer";
import DeleteLessonSlicer from "./DeleteLessonSlicer";
import GetUsersSlicer from "./GetUsersSlicer";
import DeleteStudentSlicer from "./DeleteStudentSlicer";
import GetSingleUSerSlicer from "./GetSingleUSerSlicer";
import UpdateUserDataSlicer from "./UpdateUserDataSlicer";
import GetFormConactSlicer from "./GetFormConactSlicer";
import DeleteFromContactSlicer from "./DeleteFromContactSlicer";
import GetFormJoinSlicer from "./GetFormJoinSlicer";
import DeleteFromJointSlicer from "./DeleteFromJointSlicer";
import StatisticsSlicer from "./StatisticsSlicer";
import UpdateCourseDataSlicer from "./UpdateCourseDataSlicer";
import GetSingleLessonDataSlicer from "./GetSingleLessonDataSlicer";
import getAllAdminsSlicer from "./Slices/users/getAllAdminsSlicer";
import deleteUserByIdSlicer from "./Slices/users/deleteUserByIdSlicer";
import updateUsersSlicer from "./Slices/users/updateUsersSlicer";
import updateUserPasswordSlicer from "./Slices/users/updateUserPasswordSlicer";
import DeleteLessonFileSlicer from "./Slices/lessons/DeleteLessonFileSlicer";
import getAllPaymentsSlicer from "./Slices/payments/getAllPaymentsSlicer";
import getDiplomaPaymentsSlicer from "./Slices/payments/getDiplomaPaymentsSlicer";

import getStudentCoursesSlicer from "./Slices/users/getStudentCoursesSlicer";
import deleteCourseSubscriptionSlicer from "./Slices/users/deleteCourseSubscriptionSlicer";
import deleteStudentAccessSlicer from "./Slices/users/deleteStudentAccessSlicer";

import sendCertificateToStudentSlicer from "./Slices/users/sendCertificateToStudentSlicer";

import addSubscriptionSlicer from "./Slices/users/addSubscriptionSlicer";
import getTeacherCoursesSlicer from "./Slices/teacher/getTeacherCoursesSlicer";
import getByIdTeacherDiplomaSlicer from "./Slices/teacher/getByIdTeacherDiplomaSlicer";
import getByIdTeacherCourseSlicer from "./Slices/teacher/getByIdTeacherCourseSlicer";
import getTeacherStatistice from "./Slices/teacher/getTeacherStatistice";

//Diplomes
import getAllDiplomesSlicer from "./Slices/diplomes/getAllDiplomesSlicer";
import deleteDiplomeByIdSlicer from "./Slices/diplomes/deleteDiplomeByIdSlicer";
import addDiplomeSlicer from "./Slices/diplomes/addDiplomeSlicer";
import updateDiplomeSlicer from "./Slices/diplomes/updateDiplomeSlicer";
import getDiplomeByIdSlicer from "./Slices/diplomes/getDiplomeByIdSlicer";
import UpdateImageDiplomeSlicer from "./Slices/diplomes/UpdateImageDiplomeSlicer";
import UploadDiplomeDemoVideoSlicer from "./Slices/diplomes/UploadDiplomeDemoVideoSlicer";
import getAllCoursesToDiplomeSlicer from "./Slices/diplomes/getAllCoursesToDiplomeSlicer";
import addCourseToDiplomeSlicer from "./Slices/diplomes/addCourseToDiplomeSlicer";
//quizes
import getAllQuizesSlicer from "./Slices/quizes/getAllQuizesSlicer";
import deleteQuizeByIdSlicer from "./Slices/quizes/deleteQuizeByIdSlicer";
import createQuizeSlicer from "./Slices/quizes/createQuizeSlicer";
import updateQuizeSlicer from "./Slices/quizes/updateQuizeSlicer";
import getQuizeByIdSlicer from "./Slices/quizes/getQuizeByIdSlicer";
import addQuizeToCourseSlicer from "./Slices/quizes/addQuizeToCourseSlicer";
import deleteQuizeToCourseSlicer from "./Slices/quizes/deleteQuizeToCourseSlicer";
import getQuizeByCourseIdSlicer from "./Slices/quizes/getQuizeByCourseIdSlicer";

//Questions
import deleteQuestionByIdSlicer from "./Slices/quizes/questions/deleteQuestionByIdSlicer";
import addQuestionSlicer from "./Slices/quizes/questions/addQuestionSlicer";
import updateQuestionByIdSlicer from "./Slices/quizes/questions/updateQuestionByIdSlicer";

//Promocode
import createPromocodeSlicer from "./Slices/promocode/createPromocodeSlicer";
import getAllPromocodeSlicer from "./Slices/promocode/getAllPromocodeSlicer";
import UpdatePromocodeSlicer from "./Slices/promocode/UpdatePromocodeSlicer";
import DeletePromocodeSlicer from "./Slices/promocode/DeletePromocodeSlicer";

//currancy
import updateCurrencySlicer from "./Slices/currency/updateCurrencySlicer";
import deleteCurrencySlicer from "./Slices/currency/DeleteCurrencySlicer"
import getCurrenciesSlicer from "./Slices/currency/GetCurrenciesSlicer"
import newCurrencySlicer from "./Slices/currency/NewCurrencySlicer"
import updateCurrencyApiSlice from "./Slices/currency/UpdateCurrencyApiSlice"
//newsList
import NewsListMessageSlicer from "./Slices/NewslistMessage/NewsListMessageSlicer";
//subscriptions
import getAllSubscriptionsSlicer from "./Slices/subscriptions/GetAllSubscriptionsSlicer";
import getAllCourseSubscriptionsSlicer from "./Slices/subscriptions/courses/getAllCourseSubscriptionsSlicer";
import getAllDiplomasSubscriptionsSlicer from "./Slices/subscriptions/diplomas/getAllDiplomasSubscriptionsSlicer";
import createSubscriptionDiplomasSlicer from "./Slices/subscriptions/diplomas/createSubscriptionDiplomasSlicer";
import deleteCourseSubscriptionSclicer from "./Slices/subscriptions/courses/deleteCourseSubscriptionSclicer";
import deleteDiplomaSubscriptionSclicer from "./Slices/subscriptions/diplomas/deleteDiplomaSubscriptionSclicer";
import getAllCourseAndDiplomasSlicer from "./Slices/subscriptions/getAllCourseAndDiplomasSlicer";


// jobs
import getJobsSlicer from "./Slices/jobs/GetJobsSlicer";
import addJobSlicer from "./Slices/jobs/AddJobSlicer";
import updateJobSlicer from "./Slices/jobs/UpdateJobSlicer";
import deleteJobSlicer from "./Slices/jobs/DeleteJobSlicer";

//reels
import getReelsSlicer from "./Slices/reels/GetReelsSlicer";
import getCourseReelsSlicer from "./Slices/reels/GetCourseReelsSlicer";
import addReelSlicer from "./Slices/reels/AddReelSlicer";
import addVideoToReelSlicer from "./Slices/reels/AddVideoToReelSlicer";
import updateReelDataSlicer from "./Slices/reels/UpdateReelDataSlicer";
import deleteReelSlicer from "./Slices/reels/DeleteReelSlicer";

//plans
import addPlanSlicer from "./Slices/plans/AddPlanSlicer";
import getPlansSlicer from "./Slices/plans/GetPlansSlicer";
import updatePlanSlicer from "./Slices/plans/UpdatePlanSlicer";
import deletePlanSlicer from "./Slices/plans/DeletePlanSlicer";

//feedbacks
import getFeedbacksSlicer from "./Slices/feedbacks/GetFeedbacksSlicer";
import newFeedbackSlicer from "./Slices/feedbacks/NewFeedbackSlicer";
import deleteFeedbackSlicer from "./Slices/feedbacks/DeleteFeedbackSlicer";
import updateFeedbackSlicer from "./Slices/feedbacks/UpdateFeedbackSlicer";

//settings
import getBasicSettingsSlicer from "./Slices/settings/GetBasicSettingsSlicer";
import postSettingsSlicer from "./Slices/settings/PostSettingsSlicer";

//views
import getMontlyViewsSlicer from "./Slices/views/GetMontlyViewsSlicer";
import getMonthlyMinutesViews from "./Slices/views/GetMonthlyMinutesViews";



const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  newAuth: userLoginSlicer,
  isLoged: isLogedIn,
  addUsers: AddUserSlicer,

  addCourse: AddCourseSlicer,
  getAllCourses: getAllCourses,
  singleCourseData: SingleCourseSlicer,
  deleteCourse: DeleteCourseSlicer,
  updateCourse: UpdateCourseDataSlicer,
  UploadDemoVideoSlicer: UploadDemoVideoSlicer,
  UpdateImageCourseSlicer: UpdateImageCourseSlicer,

  addLessons: addessonSlicer,
  getLessons: getAllLessonsSlicer,
  getSingleLesson: GetSingleLessonDataSlicer,
  deleteLesson: DeleteLessonSlicer,
  DeleteLessonFileSlicer: DeleteLessonFileSlicer,

  //Deiplomes
  getAllDiplomesSlicer: getAllDiplomesSlicer,
  deleteDiplomeByIdSlicer: deleteDiplomeByIdSlicer,
  addDiplomeSlicer: addDiplomeSlicer,
  updateDiplomeSlicer: updateDiplomeSlicer,
  getDiplomeByIdSlicer: getDiplomeByIdSlicer,
  UpdateImageDiplomeSlicer: UpdateImageDiplomeSlicer,
  UploadDiplomeDemoVideoSlicer: UploadDiplomeDemoVideoSlicer,
  getAllCoursesToDiplomeSlicer: getAllCoursesToDiplomeSlicer,
  addCourseToDiplomeSlicer: addCourseToDiplomeSlicer,

  getUser: GetUsersSlicer,
  getAllAdminsSlicer: getAllAdminsSlicer,
  getSingleUser: GetSingleUSerSlicer,
  deleteUser: DeleteStudentSlicer,
  updateUser: UpdateUserDataSlicer,
  updateUsersSlicer: updateUsersSlicer,
  deleteUserByIdSlicer: deleteUserByIdSlicer,
  deleteStudentAccessSlicer: deleteStudentAccessSlicer,
  updateUserPasswordSlicer: updateUserPasswordSlicer,
  sendCertificateToStudentSlicer: sendCertificateToStudentSlicer,
  getStudentCoursesSlicer: getStudentCoursesSlicer,
  deleteCourseSubscriptionSlicer: deleteCourseSubscriptionSlicer,
  addSubscriptionSlicer: addSubscriptionSlicer,

  formContact: GetFormConactSlicer,
  DeleteformContact: DeleteFromContactSlicer,
  formJoin: GetFormJoinSlicer,
  DeleteformJoin: DeleteFromJointSlicer,
  statistics: StatisticsSlicer,
  getAllPaymentsSlicer: getAllPaymentsSlicer,
  getDiplomaPaymentsSlicer: getDiplomaPaymentsSlicer,

  //subscriptions
  getAllCourseSubscriptionsSlicer: getAllCourseSubscriptionsSlicer,
  getAllDiplomasSubscriptionsSlicer: getAllDiplomasSubscriptionsSlicer,
  getAllSubscriptionsSlicer : getAllSubscriptionsSlicer,

  createSubscriptionDiplomasSlicer: createSubscriptionDiplomasSlicer,

  deleteCourseSubscriptionSclicer: deleteCourseSubscriptionSclicer,
  deleteDiplomaSubscriptionSclicer: deleteDiplomaSubscriptionSclicer,

  getAllCourseAndDiplomasSlicer: getAllCourseAndDiplomasSlicer,

  //Quizes
  getAllQuizesSlicer,
  deleteQuizeByIdSlicer,
  createQuizeSlicer,
  updateQuizeSlicer,
  getQuizeByIdSlicer,
  addQuizeToCourseSlicer,
  deleteQuizeToCourseSlicer,
  getQuizeByCourseIdSlicer,

  //Questions
  deleteQuestionByIdSlicer,
  addQuestionSlicer,
  updateQuestionByIdSlicer,

  // teacher
  getTeacherCoursesSlicer: getTeacherCoursesSlicer,
  getByIdTeacherCourseSlicer: getByIdTeacherCourseSlicer,
  getByIdTeacherDiplomaSlicer: getByIdTeacherDiplomaSlicer,
  getTeacherStatistice: getTeacherStatistice,

  //promocode
  createPromocodeSlicer,
  getAllPromocodeSlicer,
  UpdatePromocodeSlicer,
  DeletePromocodeSlicer,

  //currancy
  updateCurrencySlicer,
  //newslist message
  NewsListMessageSlicer,

  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  //form: reduxFormReducer,
  
  //jobs
  getJobsSlicer,
  addJobSlicer,
  updateJobSlicer,
  deleteJobSlicer,
  // reels
  getReelsSlicer,
  getCourseReelsSlicer,
  addReelSlicer,
  updateReelDataSlicer,
  deleteReelSlicer,
  addVideoToReelSlicer,
  // plans
  addPlanSlicer,
  updatePlanSlicer,
  deletePlanSlicer,
  getPlansSlicer,
  //fedbacks
  getFeedbacksSlicer,
  newFeedbackSlicer,
  deleteFeedbackSlicer,
  updateFeedbackSlicer,

  //settings
  getBasicSettingsSlicer,
  postSettingsSlicer,

  //views
  getMontlyViewsSlicer,
  getMonthlyMinutesViews,

  deleteCurrencySlicer,
  getCurrenciesSlicer,
  newCurrencySlicer,
  updateCurrencyApiSlice,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
